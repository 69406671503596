<template>
  <div>
    <In-Head :title="$t('reports.sold_products.title')" />

    <sub-header
      :title="$t('reports.sold_products.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <div class="flex flex-wrap lg:space-x-2" v-if="$page.props.permissions.canViewIncomeSummary">
      <sm-card
        :header-title="stat.title"
        :header-info="stat.tooltip"
        class="flex-grow-0 min-w-[240px]"
      >
        <div>
          <p class="flex items-center text-xl font-semibold leading-8 text-bo-blue">
            {{ useNumberFormat(stat.amount) }}
            <span
              v-if="stat.helper"
              class="ml-1 text-sm text-bo-gray-400">{{ stat.helper }}</span>
          </p>
        </div>
      </sm-card>
      <sm-card
        class="flex items-center flex-1"
        body-classes="flex-1">
        <div class="flex flex-wrap -mb-2">
          <info-card
            v-for="type in aggregates"
            :key="type.name"
            class="mb-2 mr-1"
            :loading="!$page.props.table?.aggregates"
            :data="type" />
        </div>
      </sm-card>
    </div>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.sold_products.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template v-else-if="col === 'sold'">
                {{ useDateFormat(slotProps.data?.sold, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canView"
                  v-tippy="$t('generic.buttons.view')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, useNumberFormat, usePriceFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  aggregates: { type: Array, required: false, default: () => [] },
});

const aggregates = computed(() => {
  return props.aggregates.map(aggregate => ({
    ...aggregate,
    money: usePriceFormat(props.table?.aggregates[aggregate.type] ?? 0),
  }));
});

const tableFields = ["product_name", "customer", "amount", "unit_price", "weighted_avg_price", "payment_type", "sold", "cash_register", "warehouse"];

const stat = computed(() => {
  const total = props.table?.aggregates.total ?? 0;

  return {
    title: "Összbevétel",
    tooltip: "Az összbevétel a megadott szűrők alapján számolt teljes bevételi értéket mutatja.",
    amount: total,
    helper: "Ft",
  };
});
</script>
