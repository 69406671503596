import { addHours, formatISO, isBefore } from "date-fns";
import throttle from "lodash/throttle";

const loadSmAlert = throttle(() => {
  document.querySelectorAll("[data-dismiss='alert']").forEach(toggler => {

    if (localStorage.getItem(`smc_alert-${toggler.getAttribute("data-id")}`)) {
      if (isBefore(new Date(), new Date(localStorage.getItem(`smc_alert-${toggler.getAttribute("data-id")}`)))) {
        toggler.parentElement.remove();
      } else {
        localStorage.removeItem(`smc_alert-${toggler.getAttribute("data-id")}`);
      }
    }

    toggler.addEventListener("click", () => {
      if (toggler.getAttribute("data-hours") && toggler.getAttribute("data-id")) {
        localStorage.setItem(`smc_alert-${toggler.getAttribute("data-id")}`, formatISO(addHours(new Date(), toggler.getAttribute("data-hours"))));
      }
      toggler.parentElement.remove();
    });
  });
}, 300);

window.loadSmAlert = loadSmAlert;
