import moment from "moment";
import AppointmentRepository from "./repository/Appointments";
import emitter from "tiny-emitter/instance";
import { useToast } from "vue-toastification";

export default {
  state: {
    endpoints: [],
    settings: [],
    repositories: {},
    calendar: null,
    currentView: null,
    zone: null,
    locations: [],

    isDragging: false,
    activePopup: null,

    saving: false,

    isCoachCalendar: false,
    defaultLocation: null,
  },
  mutations: {
    setEndpoints(state, endpoints) {
      state.endpoints = endpoints;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setZone(state, zone) {
      state.zone = zone;
    },
    setIsCoachCalendar(state, isCoachCalendar) {
      state.isCoachCalendar = isCoachCalendar;
    },
    setDefaultLocation(state, defaultLocation) {
      state.defaultLocation = defaultLocation;
    },
    initializeRepository(state) {
      state.repositories = {
        ...state.repositories,
        appointments: new AppointmentRepository(state.endpoints.appointments),
      };
    },
    setCalendarRef(state, calendar) {
      state.calendar = calendar;
    },
    setCurrentView(state, view) {
      state.currentView = view;
    },
    showPopupAt(state, {
      component,
      data
    }) {
      state.activePopup = {
        component,
        data
      };
    },
    closeActivePopup(state) {
      emitter.emit("on-close-requested");
      state.activePopup = null;
    },
    setDragging(state, isDragging) {
      state.isDragging = isDragging;
    },
    setSaving(state, value) {
      state.saving = value;
    },
  },
  getters: {
    calendarIntervalInMinute(state) {
      return state.settings.interval === "hourly" ? 60 : 30;
    },
    openFrom(state) {
      if (!state.settings.open_from) {
        return "00:00:00";
      }

      return moment(state.settings.open_from, "H:mm").format("HH:mm:ss");
    },
    openTo(state) {
      if (!state.settings.open_to) {
        return "24:00:00";
      }

      return moment(state.settings.open_to, "H:mm").format("HH:mm:ss");
    },
    openingHours(state) {
      return Object.entries(state.settings.opening_hours).reduce((hours, [idx, s]) => {
        hours[idx] = {
          isOpen: s.is_open === "1" || s.is_open === true,
          from: moment(s.from, "H:mm").format("HH:mm:ss"),
          to: moment(s.to, "H:mm").format("HH:mm:ss"),
        };
        return hours;
      }, {});
    },
    popupVisible(state) {
      return state.activePopup !== null;
    },
    isZoneWeekView(state) {
      const view = state.currentView;
      if (!view) {
        return false;
      }

      return view.type === "resourceTimeGridWeek" && state.locations.length > 1;
    },
    canBookRetrospectively(state) {
      return state.settings.retrospective_booking;
    },
    formIsSaving(state) {
      return state.saving;
    },
  },
  actions: {
    handleSuccess(store, message) {
      const toast = useToast();
      toast.success(message);
    },
    handleError(store, message) {
      const toast = useToast();
      toast.error(message);
    },
    handleApiError({
      dispatch
    }, error) {
      let message = error;
      if (error.response && error.response.data) {
        if (error.response.data.status === "invalid_data") {
          const firstErrorKey = Object.keys(error.response.data.errors)[0];
          const firstError = error.response.data.errors[firstErrorKey];

          message = firstError[0];
        } else {
          message = error.response.data.message;
        }
      }
      dispatch("handleError", message);
    },
    reloadCalendar({
      state
    }) {
      const eventSource = state.calendar.getApi().getEventSourceById("appointments-event-source");
      eventSource.refetch();
    },
    startDragging({
      commit
    }) {
      commit("setDragging", true);
      commit("closeActivePopup");
    },
    endDragging({
      commit
    }) {
      commit("setDragging", false);
    },
    async createAppointment({
      state,
      commit,
      dispatch
    }, {
      data
    }) {
      try {
        commit("setSaving", true);
        await state.repositories.appointments.create(data);
        commit("closeActivePopup");
        dispatch("handleSuccess", "Sikeresen létrehoztuk a foglalást!");
        commit("setSaving", false);
        dispatch("reloadCalendar");
      } catch (e) {
        dispatch("handleApiError", e);
        commit("setSaving", false);
      }
    },
    async updateAppointment({
      state,
      commit,
      dispatch
    }, {
      id,
      data
    }) {
      try {
        commit("setSaving", true);
        await state.repositories.appointments.update(id, data);
        commit("closeActivePopup");
        dispatch("handleSuccess", "Sikeresen módosítottuk a foglalást.");
        commit("setSaving", false);
        dispatch("reloadCalendar");
      } catch (e) {
        dispatch("handleApiError", e);
        commit("setSaving", false);
      }
    },
    async deleteAppointment({
      state,
      commit,
      dispatch
    }, {
      appointment,
      deleteAllRecurring = false
    }) {
      try {
        const message = prompt("Saját értesítő szöveg (hagyd üresen az alapértelmezetthez):");
        await state.repositories.appointments.delete(appointment.id, message, deleteAllRecurring);
        commit("closeActivePopup");
        appointment.remove();
        dispatch("handleSuccess", "Sikeresen töröltük a foglalást.");
      } catch (e) {
        dispatch("handleApiError", e);
      }
    },
  },
};
