<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <vc-date-picker
      :id="id"
      v-model="dateModelValue"
      mode="time"
      is24hr
      :masks="{ data: 'hh:mm', input: 'hh:mm'}"
      :required="required"
      @update:modelValue="updateInput($event)">
      <template #default="{ inputValue, inputEvents }">
        <input
          :id="inputId"
          ref="timePickerInputRef"
          type="text"
          name="from"
          class="sm-input-text"
          :placeholder="placeholder"
          :required="required"
          :value="inputValue"
          :disabled="disabled"
          v-on="!disabled ? inputEvents : {}">
      </template>
    </vc-date-picker>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import { format, parse } from "date-fns";

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  inputId: { type: String, required: false, default: "" },
  modelValue: { type: [Date, String], required: false, default: null },
  label: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: "" },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
});

provide("props", props);

const dateModelValue = computed(() => {
  if (props.modelValue) {
    if (props.modelValue.length > 5) {
      return parse(props.modelValue.slice(0, -3), "HH:mm", new Date());
    } else {
      return parse(props.modelValue, "HH:mm", new Date());
    }
  }

  return "";
});

const emit = defineEmits(["update:modelValue"]);

function updateInput(e) {
  let formattedDate = "";
  try {
    formattedDate = format(e, "HH:mm");
  } catch (e) {
    if (e.message === "Invalid time value") {
      formattedDate = "";
    }
  }

  emit("update:modelValue", formattedDate);
}
</script>

<style>
.vc-time-header {
  display: none !important;
}
</style>

