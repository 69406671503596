<template>
  <component
    :is="form ? 'form' : 'div'"
    class="mb-4 sm-card"
    :class="{'no-padding overflow-hidden' : noPadding, 'sm-card-new': v2}">
    <div
      v-if="headerTitle"
      class="sm-card-header"
      :class="[headerClasses, {'flex items-center justify-between': $slots.headerActions}]">
      <p class="font-bold">
        {{ headerTitle }}
        <vue-feather
          v-if="headerInfo"
          v-tippy="headerInfo"
          type="info"
          class="w-2.5" />
      </p>
      <div
        v-if="$slots.headerActions">
        <slot name="headerActions" />
      </div>
    </div>
    <div
      v-else-if="$slots.header"
      class="sm-card-header"
      :class="headerClasses">
      <slot name="header" />
    </div>
    <div
      class="sm-card-body"
      :class="bodyClasses">
      <slot />
    </div>
    <div
      v-if="$slots.footer || form"
      class="sm-card-footer"
      :class="footerClasses">
      <div
        v-if="form"
        class="flex justify-between">
        <sm-button
          v-if="back"
          :href="back"
          preset="back" />
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="emit('onSubmit')" />
      </div>
      <slot
        v-else
        name="footer" />
    </div>
  </component>
</template>

<script setup>
defineProps({
  headerTitle: { type: String, required: false, default: "" },
  headerInfo: { type: String, required: false, default: "" },
  headerClasses: { type: String, default: "" },
  bodyClasses: { type: String, default: "" },
  footerClasses: { type: String, default: "" },
  noPadding: { type: Boolean, default: false },
  form: { type: Object, required: false, default: null },
  back: { type: String, required: false, default: "" },
  v2: { type: Boolean, required: false, default: false },
});

const emit = defineEmits(["onSubmit"]);
</script>
