<template>
  <div>
    <div class="space-y-4 sm-row lg:space-y-0">
      <div
        v-for="(stat, key) in pageStats"
        :key="key"
        class="w-full sm-col lg:w-1/3">
        <sm-card
          body-classes="p-2"
          :header-title="`Top 5 ${key}`">
          <template v-if="stat.items.length !== 0">
            <div
              v-for="(entry, index) in useFormatSelect(stat.items)"
              :key="entry.id"
              class="flex items-center mb-2">
              <div
                class="tw rounded-half border-[3px]"
                :class="itemColor(index)" />
              <span
                v-tippy="entry.label"
                class="flex-1 mx-2 text-xs font-medium truncate">
                {{ entry.label.label }}
                <template v-if="entry.label.subtitle">
                  <br><span class="font-normal text-bo-gray-500">{{ entry.label.subtitle }}</span>
                </template>
              </span>
              <div class="text-right">
                <span class="text-xs">{{ percentage(stat, entry.label.count) }}%</span>
              </div>
            </div>
          </template>
          <div v-else>
            <sm-alert
              color="light"
              no-margin>
              Nincs megjeleníthető adat
            </sm-alert>
          </div>
        </sm-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";

defineProps({
  pageStats: { type: Object, required: false, default: null }
});

const colors = ref(["border-bo-blue", "border-bo-green", "border-bo-yellow-800", "border-bo-pink", "border-bo-teal", "border-bo-purple"]);

const percentage = (stat, entryCount) => {
  return Math.floor(entryCount / stat.totalCount * 100);
};

const itemColor = (index) => colors.value[index % colors.value.length];

</script>
