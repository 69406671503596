<template>
  <div>
    <In-Head :title="$t('dev_tools.product_units.title')" />

    <sub-header :title="$t('dev_tools.product_units.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`dev_tools.product_units.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'label'">
                {{ slotProps?.data?.label }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <sm-delete-button
                  :route="slotProps.data?.routes.destroy"
                  :name="`delete_${slotProps.data?.id}`"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["label", "abbreviation"]);
</script>
