<template>
  <div>
    <In-Head :title="$t('reports.sold_tickets.title')" />

    <sub-header
      :title="$t('reports.sold_tickets.title')"
      :export-route="$page.props.routes.export"
      automatic-export
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <full-line-chart
      v-model:active-category="chartCategory"
      title="Értékesítés"
      :categories="chartCategories"
      :chart-data="chartData"
      :loading="!$page.props.table"
    />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.sold_tickets.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'sold_at'">
                {{ useDateFormat(slotProps.data?.sold_at, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
      <pre><code>{{ stats }}</code></pre>
    </sm-card>
  </div>
</template>

<script setup>
import { useRandomTailwindColor } from "@/inertia/composables/charts";
import { useDateFormat } from "@/inertia/composables/global";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  permissions: { type: Object, required: false, default: () => {} },
});

const tableFields = ["customer", "ticket", "price", "sold_at", "channel", "customer_group", "stored_by"];

const chartCategories = computed(() => {
  return [
    props.permissions.canViewIncomeSummary ? { id: "price", name: "Bevétel" } : null,
    { id: "percent", name: "Jegy darabszám" },
  ].filter(i => i !== null);
});

const chartCategory = ref(chartCategories.value[0].id);

const chartData = computed(() => {
  let stats = props.table?.stats.map((stat, index) => ({
    ...stat,
    color: useRandomTailwindColor(index)
  })) ?? [];

  const fieldToUse = chartCategory.value === "price" ? "price_sum" : "sold_count";

  const sortedStats = [].concat(stats).sort(
    (a, b) => b[fieldToUse] - a[fieldToUse]
  );

  const values = sortedStats.map(i => parseInt(i[fieldToUse]));

  return {
    title: "Értékesítés",
    labels: sortedStats.map(s => s.name),
    data: values,
    colors: sortedStats.map(s => s.color),
    subtitle: chartCategory.value === "price" ? "Ft" : "db",
    summary: {
      quantity: values.reduce((sum, acc) => sum += acc, 0),
      subtitle: chartCategory.value === "price" ? "Ft" : "db",
    }
  };
});
</script>
