<template>
  <div class="sm-rich-text">
    <div
      v-if="editor"
      class="editor-toolbar">
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleBold().run()"
        :is-active="editor.isActive('bold')"
      >
        Bold
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleItalic().run()"
        :is-active="editor.isActive('italic')"
      >
        Italic
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleStrike().run()"
        :is-active="editor.isActive('strike')"
      >
        Strike
      </ToolbarButton>
      <ToolbarButton
        :on-click="setLink"
        :is-active="editor.isActive('link')"
      >
        Link
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :is-active="editor.isActive('heading', { level: 2 })"
      >
        Heading
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleBlockquote().run()"
        :is-active="editor.isActive('blockquote')"
      >
        Quote
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleCode().run()"
        :is-active="editor.isActive('code')"
      >
        Code
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleBulletList().run()"
        :is-active="editor.isActive('bulletList')"
      >
        Bullet List
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().toggleOrderedList().run()"
        :is-active="editor.isActive('orderedList')"
      >
        Ordered List
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().undo().run()"
      >
        Undo
      </ToolbarButton>
      <ToolbarButton
        :on-click="() => editor.chain().focus().redo().run()"
      >
        Redo
      </ToolbarButton>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script setup>
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Blockquote from "@tiptap/extension-blockquote";
import ToolbarButton from "./RichText/ToolbarButton.vue";

const props = defineProps({
  modelValue: { type: String, default: "", required: false },
});

const emit = defineEmits(["update:modelValue"]);

const editor = useEditor({
  content: props.modelValue,
  editorProps: {
    attributes: {
      class: "px-6 py-4",
    },
  },
  extensions: [
    StarterKit,
    Link.configure({
      openOnClick: false,
      HTMLAttributes: {
        class: "text-blue-500 hover:text-blue-700",
      },
    }),
    BulletList.configure({
      HTMLAttributes: {
        class: "list-disc mx-4",
      },
    }),
    OrderedList.configure({
      HTMLAttributes: {
        class: "list-decimal mx-4",
      },
    }),
  ],
  onUpdate: () => {
    emit("update:modelValue", editor.value.getHTML());
  },
});

const setLink = () => {
  const url = window.prompt("URL");
  if (url) {
    editor.value.chain().focus().setLink({ href: url }).run();
  } else {
    editor.value.chain().focus().unsetLink().run();
  }
};

watch(props.modelValue, (value) => {
  if (editor.value.getHTML() !== value) {
    editor.value.commands.setContent(value, false);
  }
});

onBeforeUnmount(() => {
  editor.value.destroy();
});
</script>

<style scoped>
.sm-rich-text {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editor-toolbar {
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}


.editor-content {
  padding: 1rem;
}
</style>
