<template>
  <div>
    <In-Head :title="$t('reports.coach_salaries.show.title', {name: $page.props.coach.name})" />

    <sub-header :title="$t('reports.coach_salaries.show.title', {name: $page.props.coach.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.coachSalaries"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :data="$page.props.table"
        :sorts="sorts"
      >
        <template #columns>
          <Column
            v-for="(col, index) of tableColumns"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.coach_salaries.show.table.${col}`)"
            :sortable="false"
          >
            <template #body="slotProps">
              <template v-if="col === 'is_substitute_coach'">
                <sm-boolean-flag :value="slotProps.data?.is_substitute_coach" />
              </template>
              <template v-else-if="col === 'from' || col === 'to'">
                {{ useDateFormat(slotProps.data?.[col], "date_time") }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  coach: { type: Object, required: false, default: () => { } },
  routes: { type: Object, required: false, default: () => { } },
});

const tableColumns = ["members_count", "location", "service", "is_substitute_coach", "from", "to"];

</script>
