<template>
  <In-Head :title="club.name" />

  <sub-header :title="club.name">
    <template #breadcrumbs>
      <sm-breadcrumbs :data="$page.props.breadcrumbs" />
    </template>
    <template #actions>
      <club-toggle-button />
    </template>
  </sub-header>

  <div class="sm-row">
    <div class="sm-col lg:w-4/12">
      <sm-card>
        <div class="flex items-center justify-center">
          <div class="sm-avatar sm-avatar-xxl">
            <img
              :src="club.profile_picture"
              class="rounded">
          </div>
        </div>
        <p class="mt-2 mb-0 text-base font-medium text-center text-gray-800">
          {{ club.name }}
        </p>

        <hr>

        <table class="w-full">
          <tr
            v-for="stat in stats"
            :key="stat.data">
            <td class="font-medium">
              {{ stat.name }}
            </td>
            <td class="text-right">
              {{ stat.data }}
            </td>
          </tr>
        </table>
      </sm-card>
    </div>
    <div class="sm-col lg:w-4/12">
      <sm-card :header-title="$t('dev_tools.clubs.data.default_data')">
        <table class="w-full">
          <tr
            v-for="stat in defaultData"
            :key="stat.data">
            <td class="font-medium">
              {{ stat.name }}
            </td>
            <td class="text-right">
              <template v-if="stat.route">
                <a
                  :href="stat.route"
                  class="inline-flex items-center text-bo-blue">
                  <vue-feather
                    type="link"
                    class="inline w-3 h-3 mr-1" />
                  {{ stat.data }}
                </a>
              </template>
              <template v-else>
                {{ stat.data }}
              </template>
            </td>
          </tr>
        </table>
      </sm-card>
      <sm-card :header-title="$t('dev_tools.clubs.data.registered_by.title')">
        <table class="w-full">
          <tr
            v-for="stat in registeredBy"
            :key="stat.data">
            <td class="font-medium">
              {{ stat.name }}
            </td>
            <td class="text-right">
              {{ stat.data }}
            </td>
          </tr>
        </table>
      </sm-card>
    </div>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage } from "@inertiajs/vue3";
import ClubToggleButton from "./_partials/ClubToggleButton.vue";

const page = usePage();

const club = page.props.club;

const stats = [
  {
    name: window.trans("dev_tools.clubs.data.created_at"),
    data: useDateFormat(club.stats.created_at, "long")
  },
  {
    name: window.trans("dev_tools.clubs.data.customers"),
    data: club.stats.customers
  },
  {
    name: window.trans("dev_tools.clubs.data.employees"),
    data: club.stats.employees
  },
  {
    name: window.trans("dev_tools.clubs.data.courses"),
    data: club.stats.courses
  },
];

const defaultData = [
  {
    name: window.trans("dev_tools.clubs.data.email"),
    data: club.email
  },
  {
    name: window.trans("dev_tools.clubs.data.address"),
    data: club.address
  },
  {
    name: window.trans("dev_tools.clubs.data.phone"),
    data: club.phone
  },
  {
    name: window.trans("dev_tools.clubs.data.stripe"),
    data: club.stripe.id,
    route: club.stripe.route
  },
];

const registeredBy = [
  {
    name: window.trans("dev_tools.clubs.data.registered_by.name"),
    data: club.registered_by.name
  },
  {
    name: window.trans("dev_tools.clubs.data.registered_by.email"),
    data: club.registered_by.email
  },
  {
    name: window.trans("dev_tools.clubs.data.registered_by.phone"),
    data: club.registered_by.phone
  },
];

</script>
