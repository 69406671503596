<template>
  <sm-toggle-button
    :title="$t('transactions.show.actions')"
    :options="toggleButtonOptions" />
  <sm-confirm-dialog :group="`delete_club`" />
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const page = usePage();
const form = useForm({});

const deleteAction = () => {
  confirm.require({
    group: "delete_club",
    header: window.trans("generic.delete_modal_title"),
    message: window.trans("generic.are_you_sure"),
    accept: () => {
      form.delete(page.props.routes.destroy, {
        only: ["table", "flash"]
      });
    }
  });
};

const toggleButtonOptions = ref([
  {
    id: "impersonate",
    label: window.trans("dev_tools.clubs.impersonate"),
    icon: "user-check",
    toLegacyRoute: page.props.routes.impersonate,
    visible: page.props?.permissions.canImpersonate && page.props?.permissions.canBeImpersonated
  },
  {
    id: "edit",
    label: window.trans("generic.edit"),
    icon: "edit",
    toLegacyRoute: page.props.routes.edit
  },
  {
    id: "delete",
    label: window.trans("generic.delete"),
    icon: "trash-2",
    action: deleteAction,
  },
]);
</script>
