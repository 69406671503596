<template>
  <div>
    <In-Head :title="$t('dev_tools.clubs.title')" />

    <sub-header :title="$t('dev_tools.clubs.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`dev_tools.clubs.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                <sm-avatar
                  :user="slotProps.data?.club" />
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else-if="col === 'hidden_from_public'">
                <sm-boolean-flag :value="slotProps.data?.hidden_from_public" />
              </template>
              <template v-else-if="col === 'created_at'">
                {{ useDateFormat(slotProps.data?.created_at, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-tippy="$t('generic.show')"
                  icon="eye"
                  size="icon"
                  :href="slotProps.data?.routes.show" />
                <sm-button
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <sm-button
                  v-if="slotProps.data?.permissions.canImpersonate && slotProps.data?.permissions.canBeImpersonated"
                  v-tippy="$t('dev_tools.clubs.impersonate')"
                  size="icon"
                  old-link
                  icon="user-check"
                  :href="slotProps.data?.routes.impersonate" />
                <sm-delete-button
                  :route="slotProps.data?.routes.destroy"
                  :name="`delete_${slotProps.data?.id}`"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["id", "name", "registered_by", "created_at", "subscription", "hidden_from_public"]);

</script>
