<template>
  <div class="text-left">
    <sm-button
      color="secondary"
      :title="$t('customers.checkin_modal.open_button')"
      hotkey="F3"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      size="xl"
      :modal-title="$t('customers.checkin_modal.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <template v-if="$page.props.checkinModal">
        <template v-if="$page.props.checkinModal.hasCheckinableAppointment">
          <sm-alert color="warning">
            {{ $t('customers.checkin_modal.has_checkinable_appointment') }}
          </sm-alert>
        </template>
        <template v-else>
          <sm-alert
            v-if="$page.props.checkinModal.validCheckin"
            color="light">
            <div>
              <p>{{ $t('customers.checkin_modal.valid_checkin.title') }}</p>
              <p class="mb-0">
                <strong>{{ $t('customers.checkin_modal.valid_checkin.valid_until') }}:</strong> {{ $page.props.checkinModal.validCheckin?.validUntil }}
              </p>
              <p>
                <strong>{{ $t('customers.checkin_modal.valid_checkin.used_ticket') }}:</strong> {{ $page.props.checkinModal.validCheckin?.usedTicket }}
              </p>
              <p class="mb-0">
                {{ $t('access_control.checkins.has_active_checkin.no_usage') }}
              </p>
            </div>
          </sm-alert>

          <template v-else>
            <sm-alert color="secondary">
              <p>
                {{ $t('customers.checkin_modal.choose_ticket.title') }}
                <a
                  :href="$page.props.routes.customerTickets"
                  class="font-bold">{{ $t('customers.checkin_modal.choose_ticket.link') }}</a>.
              </p>
            </sm-alert>

            <sm-alert
              v-if="!$page.props.checkinModal.availableTickets || $page.props.checkinModal.availableTickets?.length === 0"
              color="warning">
              {{ $t('access_control.checkins.no_available_ticket') }}
            </sm-alert>

            <form
              v-else
              @submit.prevent="onSubmit()">
              <sm-select
                v-model="form.seasonTicketId"
                name="seasonTicketId"
                :options="seasonTicketOptions" />
            </form>
          </template>

          <sm-alert
            v-if="form.seasonTicketId?.requiresExtender"
            color="warning">
            <p>{{ $t('customers.checkin_modal.extender_ticket.requires_extender_ticket', {ticket: form.seasonTicketId?.extenderTicket?.name}) }}</p>
            <p v-if="form.seasonTicketId?.extenderTicket?.owns">
              {{ $t('customers.checkin_modal.extender_ticket.have_extender_ticket') }}
            </p>
            <p v-else>
              {{ $t('customers.checkin_modal.extender_ticket.does_not_have_extender_ticket', {price: form.seasonTicketId?.extenderTicket.price}) }}
            </p>
          </sm-alert>

          <sm-radio-group
            v-if="form.seasonTicketId?.requiresExtender && form.seasonTicketId?.extenderTicket && form.seasonTicketId?.extenderTicket?.owns"
            v-model="form.paymentType"
            :label="$t('customers.sales_modal.payment_type')"
            name="payment-mode"
            :items="paymentTypes"
            button-group
          />
        </template>
      </template>
      <template v-else>
        <div class="flex justify-center w-100">
          <vue-feather
            type="loader"
            class="ml-1 animate-spin text-bo-gray-400" />
        </div>
      </template>
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          id="checkinSubmitButton"
          :loading="form.processing"
          :disabled="submitButtonIsDisabled"
          color="primary"
          :title="$t('customers.actions.logging_in')"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { router, useForm, usePage } from "@inertiajs/vue3";
import hotkeys from "hotkeys-js";
import { nextTick, provide, watch } from "vue";

const props = defineProps({
  showCheckin: { type: Boolean, required: false, default: false },
  checkinableAppointment: { type: [String, Number], required: false, default: null },
});

const emit = defineEmits(["updateShowCheckin"]);

const page = usePage();
const modal = ref(false);

const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const seasonTicketOptions = ref([]);
const paymentTypes = ref([]);

const form = useForm({
  seasonTicketId: seasonTicketOptions.value ? seasonTicketOptions.value[0] : null,
  paymentType: null
});

provide("form", form);

onMounted(() => {
  hotkeys("f3", "global", (e) => {
    e.preventDefault();
    if (e.repeat) {
      return;
    } else {
      toggleModalVisibility();
    }
  });
});

const loadModalData = () => {
  const data = {};

  if (props.checkinableAppointment) {
    data["appointment_id"] = props.checkinableAppointment;
  }

  router.reload({ data, only: ["checkinModal"] }, { preserveState: true });
  router.on("finish", () => {
    if (page.props.checkinModal?.availableTickets) {
      seasonTicketOptions.value = page.props.checkinModal?.availableTickets;
      paymentTypes.value = useFormatSelect(page.props.checkinModal?.paymentTypes);
      form.defaults("seasonTicketId", seasonTicketOptions.value[0]);
      form.reset("seasonTicketId");
    }
    nextTick(() => {
      document.querySelector("#checkinSubmitButton")?.focus();
    });
  });
};

watch(modal, (e) => {
  nextTick(() => {
    if (e === true) {
      loadModalData();
    } else {
      emit("updateShowCheckin", {
        visible: false,
        appointmentId: null,
      });
      window.history.replaceState(null, "", window.location.pathname);
    }
  });
});

watch(() => props.showCheckin, (e) => {
  if (e === true) {
    modal.value = true;
  }
});

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      seasonTicketId: data.seasonTicketId?.id
    }))
    .post(page.props.checkinModal?.routes.checkIn, {
      only: ["appointments", "tickets", "clubOpen", "customerCheckedIn", "hasAycmQuickCheckin", "checkinModal", "customer", "errors", "flash", "latestCheckinValidUntil"],
      onSuccess: () => {
        form.reset();
        toggleModalVisibility();
      }
    });
};

const submitButtonIsDisabled = computed(() => {
  if (page.props.checkinModal?.hasCheckinableAppointment) {
    return true;
  }

  return !page.props.checkinModal?.validCheckin && seasonTicketOptions.value.length === 0;
});
</script>
