<template>
  <div>
    <In-Head :title="$t('dev_tools.plans.title')" />

    <sub-header :title="$t('dev_tools.plans.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-table
        :table-data="$page.props.plans"
        table-heading-key="dev_tools.plans.table"
        :table-columns="tableColumns">
        <template #actions="slotProps">
          <div
            v-if="$page.props.permissions.canCreate"
            class="flex items-center justify-end space-x-1">
            <sm-button
              v-tippy="$t('generic.details')"
              icon="eye"
              size="icon"
              old-link
              :href="slotProps.data?.routes.show" />
            <sm-button
              v-tippy="$t('generic.edit')"
              preset="edit-icon"
              old-link
              :href="slotProps.data?.routes.edit" />
          </div>
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableColumns = ref(["id", "name"]);
</script>
