<template>
  <div class="w-full mb-4 sm-col lg:w-8/12 lg:mb-0">
    <div class="flex">
      <div class="w-20 h-20 sm-avatar lg:w-32 lg:h-32">
        <img
          :src="$page.props.customer.profilePicture"
          class="max-w-full rounded">
      </div>

      <div class="flex-1 pb-2 ml-4 lg:pb-0">
        <span class="block text-base font-bold">
          {{ $page.props.customer.fullName }}
          <small v-if="$page.props.customer.nickname">({{ $page.props.customer.nickname }})</small>
        </span>
        <a
          class="block mb-1 text-xxs text-bo-blue"
          :href="`mailto:${$page.props.customer.email }`">
          {{ $page.props.customer.email }}
        </a>
        <span
          class="block text-xs">
          <span class="font-semibold">{{ $t('customers.generic.info.data.phone') }}: </span>
          {{ $page.props.customer.phone ? $page.props.customer.phone : '-' }}
        </span>
        <span
          class="block text-xs">
          <span class="font-semibold">{{ $t('customers.generic.info.data.birth_date') }}: </span>
          <span
            v-if="$page.props.customer.birthDate">
            {{ useDateFormat($page.props.customer.birthDate) }}
            ({{ $page.props.customer.age }})
          </span>
          <span v-else>-</span>
        </span>
        <span class="block text-xs">
          <span class="font-semibold">{{ $t('customers.generic.info.data.zip_code') }}: </span>
          {{ $page.props.customer.zipCode ? $page.props.customer.zipCode : '-' }}
        </span>
        <span class="block text-xs">
          <span class="font-semibold">{{ $t('customers.generic.info.data.gender') }}: </span>
          {{ $page.props.customer.gender ? $t(`genders.${$page.props.customer.gender}`) : '-' }}
        </span>
        <span class="flex items-center text-xs">
          <span class="font-semibold">{{ $t('customers.generic.info.data.newsletter_enabled') }}: </span>
          <vue-feather
            :type="$page.props.customer.newsletterEnabled ? 'check' : 'x'"
            :class="$page.props.customer.newsletterEnabled ? 'text-bo-green' : 'text-bo-red'"
            class="w-4 h-4 ml-1" />
        </span>
        <span class="block text-xs">
          <span class="font-semibold">{{ $t('customers.generic.info.data.registered_in') }}: </span>
          {{ $t(`customers.generic.info.data.register_types.${$page.props.customer.registerType}`) }}
        </span>
        <span
          v-if="$page.props.customer.isTrashed"
          class="block text-xs text-bo-red">
          <span class="font-semibold">{{ $t('customers.generic.info.data.deleted') }}:</span>
          {{ $t('customers.generic.info.data.deleted_description', {date: useDateFormat($page.props.customer.deletedAt), name: $page.props.customer.deletedBy ? $page.props.customer.deletedBy : 'Admin'}) }}
        </span>
      </div>
    </div>
    <div class="flex">
      <span class="block text-xs">
        <span class="font-semibold">{{ $t('customers.generic.info.data.sportmate_user') }}:</span>
        <div class="flex justify-between">
          <a
            class="block text-xs"
            :href="`mailto:${$page.props.customer.sportmateEmail }`">
            {{ $page.props.customer.sportmateEmail }}
          </a>
        </div>
        <change-email-modal
          v-if="!$page.props.customer.hasGeneratedEmail && $page.props.permissions.canChangeEmail"
          @open-profile-merge="openProfileMergeModal"
        />
        <profile-merge-modal
          v-if="$page.props.permissions.canMergeProfiles"
          ref="profileMergeModalRef"
          class="mt-4"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import ChangeEmailModal from "@/inertia/pages/customers/show/_partials/info/ChangeEmailModal.vue";
import ProfileMergeModal from "@/inertia/pages/customers/show/_partials/info/ProfileMergeModal.vue";

const profileMergeModalRef = ref(null);

const openProfileMergeModal = (e) => {
  profileMergeModalRef.value.openAndPreload(e.user.user);
};
</script>
