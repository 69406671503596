<template>
  <div>
    <In-Head :title="$t('dev_tools.user_reports.show.title')" />

    <sub-header :title="$t('dev_tools.user_reports.show.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card
      :header-title="$t('dev_tools.user_reports.show.data.title')">
      <table class="sm-table sm-table-no-heading sm-table-bordered">
        <tbody>
          <tr>
            <td><strong>{{ $t('dev_tools.user_reports.table.name') }}</strong></td>
            <td>{{ report.name }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('dev_tools.user_reports.table.user') }}</strong></td>
            <td>{{ report.user }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('dev_tools.user_reports.table.club') }}</strong></td>
            <td>{{ report.club }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('dev_tools.user_reports.table.message') }}</strong></td>
            <td>{{ report.message }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('dev_tools.user_reports.table.status') }}</strong></td>
            <td>{{ report.status }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('dev_tools.user_reports.table.created_at') }}</strong></td>
            <td>{{ useDateFormat(report.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage } from "@inertiajs/vue3";

const page = usePage();
const report = ref(page.props.report);
</script>
