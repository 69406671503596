<template>
  <div>
    <In-Head :title="$t('dev_tools.system_news.title')" />

    <sub-header :title="$t('dev_tools.system_news.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <form @submit.prevent="onSubmit()">
      <sm-card>
        <sm-input
          v-model="form.title"
          name="title"
          :label="$t('system_news.title')"
        />
      </sm-card>

      <sm-card>
        <template #header>
          <div class="flex justify-between items-center">
            <h3 class="text-lg font-medium">
              {{ $t('system_news.slides') }}
            </h3>
            <sm-button
              preset="primary"
              size="sm"
              @click="addSlide"
            >
              {{ $t('system_news.add_slide') }}
            </sm-button>
          </div>
        </template>

        <div class="space-y-6">
          <div
            v-for="(slide, index) in form.slides"
            :key="index"
            class="p-4 border border-gray-200 rounded-lg space-y-4"
          >
            <div class="flex justify-between items-center">
              <h4 class="font-medium">
                {{ $t('system_news.slide') }} #{{ index + 1 }}
              </h4>
              <sm-button
                preset="danger"
                size="sm"
                @click="removeSlide(index)"
              >
                {{ $t('system_news.remove') }}
              </sm-button>
            </div>

            <sm-input
              v-model="slide.title"
              name="title"
              :label="$t('system_news.slide_title')"
            />
            <sm-file
              v-model="slide.uploadedImage"
              name="image"
              :label="$t('system_news.slide_image')"
            />
            <sm-rich-text v-model="slide.description" />
          </div>

          <div
            v-if="!form.slides.length"
            class="text-center text-gray-500 py-8">
            {{ $t('system_news.no_slides') }}
          </div>
        </div>
      </sm-card>

      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const props = defineProps({
  systemNews: { type: Object, required: false, default: null, },
  submitRoute: { type: String, required: true },
});

const form = useForm({
  title: props.systemNews?.title ?? "",
  slides: props.systemNews?.slides ?? [],
});

function onSubmit() {
  form
    .transform(data => {
      return {
        ...data,
        _method: props.systemNews ? "put" : "post"
      };
    })
    .post(props.submitRoute);
}

function addSlide() {
  form.slides = form.slides.concat({
    title: "",
    uploadedImage: null,
    description: "",
  });
}

function removeSlide(index) {
  form.slides.splice(index, 1);
}

provide("form", form);
</script>
